* {
  box-sizing: border-box;
}

body {
	background: #11111a;
	color: #eee;
}

a {
	color: #ccc;
	text-decoration: underline;
	cursor: pointer;
}

.slide a:hover {
	color: #fff;
	text-shadow: 0 2px 2px rgba(0,0,20,0.75);
}

/* taken from https://codepen.io/maxakohler/pen/ZjrOpx */
.wrapper {
	display: flex;
}

.wrap,
.wrapper,
.container {
  margin: 0;
  padding: 0;
}

.container {
  	width: 100%;
	max-height: 100vh;
	overflow-y: scroll;
	/* this may not be that helpful */
	/*scroll-snap-type: y mandatory;*/
}

.slide {
	padding: 3rem;
	background: #334;
	text-align: left;
	scroll-snap-align: start;
	display: flex;
	flex-flow: column;
	justify-content: center;
	min-height: 100vh;

	/*margin-bottom: 20px;*/
	overflow-x: hidden;
}

.slide:nth-child(even) {
	background: #556;
}

.slide > * {
	transition: opacity 1s;
}

.slide > div > ul,
.slide > div > ol,
.slide > div > .quote-container,
.slide > div > p,
.slide .im-holder {
	transition: transform 1.4s;
	transform: translate(0, 0) scale(1);
}

.slide.hidden > * {
	opacity: 0;
}

.slide.hidden > div > ul,
.slide.hidden > div > ol,
.slide.hidden > div > .quote-container,
.slide.hidden > div > p {
	transform: translate(0, 40px);
}

.slide.hidden .im-holder.right {
	transform: translate(200px, 0) scale(1.5);
}

.slide.hidden .im-holder.left {
	transform: translate(-100px, 0) scale(1.5);
}

nav {
  position: fixed;
  z-index: 1;
  background: rgba(0,0,0,0.75);
  width: 100%;
  display: flex;
}

nav > a {
	flex: 1;
	text-align: center;
	padding: 1rem;
	text-decoration: none;
}

nav > a.active,
nav > a:hover {
	background: #657ea5;
	color: white;
}

code,
.language-BIBLE,
.language-Bible,
.language-bible,
h1, h2, h3, h4, h5, h6 {
	Font-Family: 'Merriweather', Serif;
	word-break: normal;
}

body {
	Font-Family: 'Muli', Sans-Serif;
}

/* sizes */

h1   { font-size: 3.0rem; line-height: 3.8rem; }
h2   { font-size: 2.2rem; line-height: 2.6rem; }
h3   { font-size: 2.0rem; line-height: 2.4rem; }
h4   { font-size: 1.8rem; line-height: 2.2rem; }
h5   { font-size: 1.6rem; line-height: 2.0rem; }
h6   { font-size: 1.4rem; line-height: 1.8rem; }
body { font-size: 1.2rem; line-height: 1.4rem; }
sub  { font-size: 0.6rem; line-height: 1.0rem; Font-Family: 'Merriweather', Serif; }

@media only screen and (max-width: 800px) {
	h1   { font-size: 1.9rem; line-height: 2.1rem; }
	h2   { font-size: 1.8rem; line-height: 2.0rem; }
	h3   { font-size: 1.7rem; line-height: 1.9rem; }
	h4   { font-size: 1.6rem; line-height: 1.8rem; }
	h5   { font-size: 1.5rem; line-height: 1.7rem; }
	h6   { font-size: 1.4rem; line-height: 1.6rem; }
  }

.quote-container > p {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	text-shadow: 0 2px 2px rgba(0,0,20,0.75);
	border-left: 4px solid white;
	white-space: break-spaces;
	color: rgba(249, 249, 252, 0.75);
}

.im-holder {
	padding-top: 0.5rem;
	padding-top: 0.5rem;
}

.im-holder > img {
	max-width: 66%;
	max-height: 350px;
	margin-left: 10%;
	display: block;
}

/* animation, https://tobiasahlin.com/moving-letters/#16 */

.ml16 {
	overflow: hidden;
}

.ml16 .letter {
	display: inline-block;
}

/* spinner */

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
	0% {
	  transform: scale(0);
	}
	100% {
	  transform: scale(1);
	}
  }
  @keyframes lds-ellipsis3 {
	0% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0);
	}
  }
  @keyframes lds-ellipsis2 {
	0% {
	  transform: translate(0, 0);
	}
	100% {
	  transform: translate(24px, 0);
	}
  }
  